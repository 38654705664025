<template>
  <a-spin :spinning="loading">
    <a-empty
      v-if="course_list.length == 0"
      description="暂无课程"
      style="padding-top: 50px"
    ></a-empty>
    <div v-else class="course-box flex wrapper">
      <div
        class="box-e flex flex-between"
        v-for="(item, i) in course_list"
        :key="i"
        :style="{ marginRight: (i + 1) % 2 == 0 ? '' : '60px' }"
        @click="goDetail(item.course_id)"
      >
        <div class="flex">
          <div class="course-image-wrap mr-20">
            <img :src="item.course_image" class="img" />
            <div
              class="tags"
              v-if="item.course_hassignup == 1 && item.course_isfinished != 1"
            >
              学习中
            </div>
            <div class="tags2" v-if="item.course_isfinished == 1">已学完</div>
          </div>
          <div class="course-info">
            <div class="font-20">{{ item.course_name }}</div>
            <div>
              <div class="num font-16">
                <span class="mr-10">共{{ item.course_resource_num }}节课</span>
                <span v-if="item.course_hassignup == 1">
                  已学<span
                    style="font-weight: bold; color: #1276cb; font-size: 18px"
                    >{{ item.course_record_finish_num }}</span
                  >节课
                </span>
              </div>
              <div class="font-16 text-gray">
                {{ item.course_start_time + " — " + item.course_end_time }}
              </div>
            </div>
          </div>
        </div>
        <img class="arrow" src="~@/assets/right-arrow.png" />
      </div>
      <a-pagination
        v-model:current="page.page"
        v-model:pageSize="page.limit"
        show-size-changer
        :hideOnSinglePage="true"
        :total="count"
        @change="onShowSizeChange"
        :pageSizeOptions="['15', '30', '50', '100']"
      ></a-pagination>
      <div class="mybtn" @click="gomy">
        <div>
          <div>我的</div>
          <div>课程</div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script setup>
import { getCourseList } from "@/api/main.js";
import { useStore } from "vuex";
import { ref } from "vue";
import { encode } from "js-base64";
import url from "@/utils/url.js";
const store = useStore();
const loading = ref(false);
const course_list = ref([]);
const count = ref(0);
const page = ref({
  page: 1,
  limit: 15
});
const getCurse = async () => {
  loading.value = true;
  const res = await getCourseList({
    user_id: store.state.user.user_id,
    park_id:store.state.user.user_park_id,
    ...page.value
  });
  count.value = res.count;
  // console.log(res.data.course);
  loading.value = false;
  course_list.value = res.data.course;
};
getCurse();
const goDetail = id => {
  url.navigateTo("/course/detail", { t: encode(`${id}`) });
};
const onShowSizeChange = (page, limit) => {
  getCurse();
};
const gomy = () => {
  url.navigateTo("/course/mycourse");
};
</script>
<style scoped lang="less">
.tags,.tags2 {
  width: 64px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 23px;
  border-radius: 10px 0 10px 0;
  background-color: #d63030;
  color: #ffffff;
}

.tags2 {
  background: #afafaf;
  color: #ffffff;
}
.mybtn {
  width: 80px;
  height: 80px;
  background-color: #1276cb;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.course-box {
  flex-wrap: wrap;
  .box-e {
    height: 190px;
    cursor: pointer;
    width: 570px;
    border-bottom: 1px solid #dfdfdf;
    .course-image-wrap {
      position: relative;
      .img {
        width: 180px;
        height: 130px;
        border-radius: 10px;
      }
    }

    .course-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 130px;
      padding: 5px 0;
      box-sizing: border-box;
      .name {
        width: 340px;
        line-height: 1;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .arrow {
        height: 16px;
      }
    }
  }
}
.ant-pagination {
  text-align: center;
  margin: 30px auto;
}
</style>
